<template lang="html">
  <div id="enroll-page">

    <section class="main-section">
      <div class="box-main-title">
        <h1 class="title-st-1">Enroll registration form</h1>
      </div>

      <b-form class="container oversized-container main-container" @submit="onSubmit" autocomplete="off">
        <div class="row" v-if="step == 1">

          <!-- Column -->
          <div class="col-lg-6 mb-4">
            <div class="box-white h-100">
              <div class="row pt-2">
                <div class="col-12">
                  <h5 class="box-title">Account information</h5>
                </div>

                <b-form-group class="col-lg-6 cus-f-group-1" label="First name *">
                  <b-form-input type="text" v-model="form.user.nombre" required placeholder="" name="nombre" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Last name *">
                  <b-form-input type="text" v-model="form.user.apellidos" required placeholder="" name="apellidos" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-12 cus-f-group-1" label="Professional / Occupational License Number *">
                  <b-form-input :disabled="disabledQuantity" type="text" v-model="form.user.licencia" required placeholder="" name="licencia" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col-xl-6 cus-f-group-1" label="Email *">
                  <b-form-input :disabled="disabledQuantity" type="email" v-model="form.user.email" required placeholder="" name="email_cart" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col-xl-6 cus-f-group-1" label="Confirm email *" v-if="!$root.logged">
                  <b-form-input :disabled="disabledQuantity" type="email" v-model="form.user.emailConf" required placeholder="" readonly  onfocus="this.removeAttribute('readonly');" name="emailConf" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1 c-f-g-pass" label="Password *" v-if="!$root.logged">
                  <b-form-input :disabled="disabledQuantity" id="i-1" type="password" v-model="form.user.password" required placeholder="" name="password_cart" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                  <i class="fas ic-eye" v-bind:class="{ 'fa-eye' : showpass, 'fa-eye-slash' : !showpass }"  @click="showpass = !showpass"></i>
                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1 c-f-g-pass" label="Confirm password *" v-if="!$root.logged">
                  <b-form-input :disabled="disabledQuantity" id="i-2" type="password" v-model="form.user.passwordConf" required placeholder="" name="passwordConf" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                  <i class="fas ic-eye" v-bind:class="{ 'fa-eye' : showpasstwo, 'fa-eye-slash' : !showpasstwo }"  @click="showpasstwo = !showpasstwo"></i>
                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Phone number">
                  <b-form-input type="number" v-model="form.user.telefono" placeholder="" @input="acceptNumber" maxlength="14"></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Identification" v-show="requiredId == true">
                  <input id="img-photo-i1" name="image" accept="image/*,.pdf" type="file">

                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Date of Birthday" v-show="requireBirthday == true">
                  <b-form-input type="date" v-model="form.user.fecha_nacimiento" placeholder=""></b-form-input>
                </b-form-group>

              </div>
            </div>
          </div>
          <!--  -->

          <!-- Column -->
          <div class="col-lg-6 mb-4">
            <div class="box-white">
              <div class="row pt-2">
                <div class="col-12">
                  <h5 class="box-title">Billing information</h5>
                </div>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Address *">
                  <b-form-input type="text" v-model="form.address" required placeholder="" name="address" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col-lg-6 cus-f-group-1" label="Address line 2">
                  <b-form-input type="text" v-model="form.addressTwo" placeholder=""></b-form-input>
                </b-form-group>



                <b-form-group class="col-lg-4 cus-f-group-1" label="State *">
                  <b-form-select v-model="form.state_id">
                    <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                    <b-form-select-option :value="s.id" v-for="(s,indx) in states" :key="indx">{{s.name}}</b-form-select-option>

                  </b-form-select>
                </b-form-group>

                <!-- <b-form-group class="col-lg-4 cus-f-group-1" label="City *">
                  <b-form-select v-model="form.town_id">
                    <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                    <b-form-select-option :value="s.id" v-for="(s,indx) in towns" :key="indx">{{s.name}}</b-form-select-option>

                  </b-form-select>
                </b-form-group> -->

                <b-form-group class="col-lg-4 cus-f-group-1" label="City *">
                  <b-form-input type="text" v-model="form.town" required placeholder="" name="town" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>


                <b-form-group class="col-lg-4 cus-f-group-1" label="ZIP *">
                  <b-form-input type="text" minlength="5" maxlength="5" v-model="form.zip_code" required placeholder="" name="zip_code" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <div class="col-12">
                  <hr class="mt-0" />
                </div>

                <div class="col-12">
                  <h5 class="box-title">Payment information</h5>
                </div>

                <div class="col-lg-6 mb-3 mb-lg-0 text-center text-lg-left align-self-center">
                  <img class="mb-lg-4 img-payments" src="/public/images/shared/payments.svg">
                </div>

                <b-form-group class="col-lg-6 cus-f-group-1 custom-promo-group" label="Have a promo code?">
                  <b-form-input type="text" v-model="form.coupon" placeholder="Type your code here"></b-form-input>
                  <b-button type="button" class="btn-add-promo" @click="checkCoupon">Add</b-button>
                </b-form-group>

                <!-- <b-form-group class="col-lg-12 cus-f-group-1" label="Name on card *">
                  <b-form-input type="text" v-model="form.user.nombre" required placeholder="" name="nombre_card" oninvalid="this.setCustomValidity('Complete this field')"></b-form-input>
                </b-form-group> -->

                <b-form-group class="col-lg-5 cus-f-group-1" label="Card Number* ( 15 or 16 digits )">
                  <b-form-input type="text" minlength="15" maxlength="16" v-model="form.cardNo" required="required" placeholder="" name="cardNo" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

                <b-form-group class="col cus-f-group-1 exp-date" label="Exp. date *">
                  <b-form-select required v-model="form.cardMonth" name="cardMonth" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>00</b-form-select-option>
                    <b-form-select-option value="1">01</b-form-select-option>
                    <b-form-select-option value="2">02</b-form-select-option>
                    <b-form-select-option value="3">03</b-form-select-option>
                    <b-form-select-option value="4">04</b-form-select-option>
                    <b-form-select-option value="5">05</b-form-select-option>
                    <b-form-select-option value="6">06</b-form-select-option>
                    <b-form-select-option value="7">07</b-form-select-option>
                    <b-form-select-option value="8">08</b-form-select-option>
                    <b-form-select-option value="9">09</b-form-select-option>
                    <b-form-select-option value="10">10</b-form-select-option>
                    <b-form-select-option value="11">11</b-form-select-option>
                    <b-form-select-option value="12">12</b-form-select-option>
                  </b-form-select>

                  <div class="line">/</div>

                  <b-form-select required v-model="form.cardYear" name="cardYear" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')">
                    <b-form-select-option :value="null" disabled>0000</b-form-select-option>
                    <b-form-select-option :value="currentYear + yInx" v-for="(n, yInx) in 11" :key="'yInx-'+yInx">{{ currentYear + yInx }}</b-form-select-option>
                  </b-form-select>
                </b-form-group>

                <b-form-group class="col cus-f-group-1" label="CVV *">
                  <b-form-input type="text" minlength="2" maxlength="4" v-model="form.cardCvv" required placeholder="" name="cardCvv" oninvalid="this.setCustomValidity('Complete this field')" oninput="setCustomValidity('')"></b-form-input>
                </b-form-group>

              </div>
            </div>
          </div>
          <!--  -->

          <!-- Column -->
          <div class="col-lg-6 mb-4">
            <div class="box-white">

              <div class="row pt-2"  v-for="(course,indx) in form.courses" :key="indx">
                <div class="col-12">
                  <h5 class="box-title">Optional Add-ons - {{course.name}}</h5>
                </div>

                <div class="col-12">
                  <div class="box-add-ons">
                    <div class="row" v-if="course.hasAudioNarrator == 1">
                      <div class="col-sm col-text">
                        <b-form-checkbox
                          class="cus-f-checkbox-2"
                          v-model="form.courses[indx].narrator"
                          name="checkbox-addons-1"
                          :value="true"
                          :unchecked-value="null"
                          @change="calculateTotals">
                          <span>Audio Narrator</span>
                        </b-form-checkbox>
                      </div>

                      <div class="col-sm col-price">
                        ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.audioNarratorPrice) }} USD
                      </div>
                    </div>

                    <div class="row" v-if="course.hasAlert == 1">
                      <div class="col-sm col-text">
                        <b-form-checkbox
                          class="cus-f-checkbox-2"
                          v-model="form.courses[indx].textemail"
                          name="checkbox-addons-2"
                          :value="true"
                          :unchecked-value="null"
                          @change="calculateTotals">
                          <span>Text/Email alert upon submission of credits to the state</span>
                        </b-form-checkbox>
                      </div>

                      <div class="col-sm col-price">
                        ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.alertPrice) }} USD
                      </div>
                    </div>

                    <div class="row" v-if="course.hasStandardShipping == 1">
                      <div class="col-sm col-text">
                        <b-form-checkbox
                          :disabled="(form.courses[indx].fastShipping)"
                          class="cus-f-checkbox-2"
                          v-model="form.courses[indx].standardShipping"
                          name="checkbox-addons-3"
                          :value="true"
                          :unchecked-value="false"
                          @change="calculateTotals">
                          <span>Professionally printed certificate: <strong class="f-w-600">Standard Shipping</strong></span>
                        </b-form-checkbox>
                      </div>

                      <div class="col-sm col-price">
                        ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.standardShippingPrice) }} USD
                      </div>
                    </div>

                    <div class="row" v-if="course.hasDayShipping == 1">
                      <div class="col-sm col-text">
                        <b-form-checkbox
                          :disabled="(form.courses[indx].standardShipping)"
                          class="cus-f-checkbox-2"
                          v-model="form.courses[indx].fastShipping"
                          name="checkbox-addons-3"
                          :value="true"
                          :unchecked-value="false"
                          @change="calculateTotals">
                          <span>Professionally printed certificate: <strong class="f-w-600">1-2 Day Shipping</strong></span>
                        </b-form-checkbox>
                      </div>

                      <div class="col-sm col-price">
                        ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.dayShippingPrice) }} USD
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <!--  -->

          <!-- Column -->
          <div class="col-lg-6">
            <div class="box-white box-course-info">
              <div class="row">
                <div class="col-12">
                    <h5 class="box-title">Courses selected</h5>
                  </div>

                <div class="col-12" v-for="(course,indx) in form.courses" :key="indx" style="padding-top: 10px; padding-bottom:10px; border-bottom:1px solid">

                  <div class="col-md-12 mt-2 mt-md-0 pl-md-1 col-text">
                    <p style="text-align: right;"><a class="btn btn-danger btn-sm" @click="deleteCourse(indx)"><i class="fas fa-times"></i></a></p>
                    <h3 style="text-align: center;">{{course.name}}</h3>

                    <div class="py-2 img">
                      <div class="placed-backg" style="background-size: contain !important;" v-bind:style="{ backgroundImage: 'url('+ course.image_url + ')' }"></div>
                      <img src="/public/images/pages/courses/img-course-2.png">
                    </div>

                    <div class="col-quantity2" style="display: flex; justify-content: center;" >
                      <b-form-input autocomplete="one-time-code" :disabled="disabledQuantity" @change="calculateTotals" id="couse_quantity" name="couse_quantity" class="input-q" type="text" min="0" v-model="course.quantity" :data.sync="course.quantity" @keypress="isNumber($event)" style="border: 0; border: 1px solid #feca0c;border-radius: 6px;font-family: Arial,Noto Sans,sans-serif;font-size: 1rem;font-weight: 600; height: 34px;padding-left: 3px;padding-right: 3px;padding-top: 7px;text-align: center;width: 70px;"/>
                    </div>
                    <div class="row box-button">
                      <div class="col-lg-5 col-more">
                        <router-link class="c-more" target="_blank" :to="'/courses/checkout/'+course.state+'/'+course.custom_url">See more information</router-link>
                      </div>

                      <div class="col-lg-7">
                        <span class="box-price">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.price) }} USD</span>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-5 col-image">
                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+ course.image_url + ')' }">
                      <img src="/public/images/pages/courses/img-course.png">
                    </div>
                  </div>

                  <div class="col-md-7 mt-2 mt-md-0 pl-md-1 col-text">

                    <div class="box-descr" v-html="course.description"></div>
                    <div class="col-quantity2" style="display: flex;" >
                      <b-form-input autocomplete="one-time-code" :disabled="disabledQuantity" @change="calculateTotals" id="couse_quantity" name="couse_quantity" class="input-q" type="text" min="0" v-model="course.quantity" :data.sync="course.quantity" @keypress="isNumber($event)" style="border: 0; border: 1px solid #ce0015;border-radius: 0;font-family: Arial,Noto Sans,sans-serif;font-size: 1rem;font-weight: 600; height: 34px;padding-left: 3px;padding-right: 3px;padding-top: 7px;text-align: center;width: 70px;"/>
                    </div>

                    <div class="row box-button">
                      <div class="col-lg-5 col-more">
                        <router-link class="c-more" target="_blank" :to="'/courses/checkout/'+course.state+'/'+course.custom_url">See more information</router-link>
                      </div>

                      <div class="col-lg-7">
                        <span class="box-price">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.price) }} USD</span>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div class="col-12 col-text">
                  <hr class="mt-4" />
                  <h5 class="box-title">Order summary</h5>

                  <table class="table-summary">
                    <tbody>
                      <tr>
                        <th>Price</th>
                        <td>${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(form.subtotal) }} USD</td>
                      </tr>

                      <tr>
                        <th>Add-ons</th>
                        <td>${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(form.total_adds) }} USD</td>

                        </tr>
                      <tr v-if="(form.total_discount > 0)">
                        <th>Discount  ({{form.percentage_discount}}
                          <span v-if="coupon.type == 'Porcentaje'">%</span>
                          <span v-else-if="coupon.type == 'Cantidad'">USD</span>
                          <span v-else>%</span>)</th>
                        <td>${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(form.total_discount) }} USD</td>
                      </tr>

                      <tr>
                        <th>Total to pay</th>
                        <td><span class="text-red">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(form.total) }} USD</span></td>
                      </tr>
                    </tbody>
                  </table>

                  <b-form-checkbox class="mt-3 cus-f-checkbox" v-model="form.tos" name="usertype" value="1" unchecked-value="" required>
                    I accept the <router-link target="_blank" to="/terms">terms and conditions</router-link> of 1 Day Courses
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- Column -->
          <div class="col-12">
            <div class="d-block box-submit">
              <b-button type="submit" class="btn-submit">Check out & begin</b-button>
            </div>
          </div>
          <!--  -->

        </div>

        <div class="row" v-if="step == 2">
          <!-- Column -->
          <div class="col-lg-12 mb-4">
            <div class="box-white box-thanks h-100">
              <p class="mb-3">
                <i class="fal fa-check-circle check-icon"></i>
              </p>

              <h3 class="txt-purchased">You have purchase 1 course:</h3>
              <h2 class="txt-course-name">{{course.name}}</h2>
              <p class="mt-4">Course ID<b>{{course.id }}</b></p>
              <p class="mt-4">Total Paid<b>${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(form.total) }} USD</b></p>

              <!-- <h5 class="txt-operation-id">Operation ID: <u>{{order_id}}</u></h5> -->

              <p class="mt-4">You will receive an email confirmation</p>

              <p class="mt-4">
                <router-link class="btn btn-red- f-w-600" to="/access">Start the course now</router-link>
              </p>
            </div>
          </div>
          <!--  -->
        </div>
      </b-form>

      <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        <div v-html="modal.msg"></div>

      </sweet-modal>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      step: 1,

      showpass:false,
      showpasstwo:false,

      currentYear: 0,

      form: {
        user:{
          nombre:null,
          apellidos:null,
          licencia:null,
          email:null,
          emailConf:null,
          telefono:null,
          fecha_nacimiento:null,
          courses:null,


        },
        firstName: null,
        cardMonth: null,
        cardYear: null,

        narrator:null,
        textemail:null,
        standardShipping:null,
        fastShipping:null,
        courses_id:null,

        coupons_id:null,
        total_discount:0,
        percentage_discount:0,
        total_adds:0,
        total:null,
        subtotal:null,
        state_id:null,
        town_id:null,
        town:null,
        address:null,
        addressTwo:null,
        refer:null,

      },
      states: [],
      towns: [],
      course:{},
      coupon:{},
      modal:{
        msg:'',
        icon:'',
        block:false,
      },
      order_id:null,
      disabledQuantity:true,
      requiredId:false,
      requireBirthday:false
    }
  },
  computed:{
    total:function(){
        var total_total_ads = 0;
          var subtotal = 0;
          var countquantity = 0;
          for (let x = 0; x < this.form.courses.length; x++) {
              var total_adds = 0;
             if (this.form.courses[x].narrator == true) {
                total_adds += parseFloat(this.form.courses[x].audioNarratorPrice);
              }
              if (this.form.courses[x].textemail == true) {
                total_adds += parseFloat(this.form.courses[x].alertPrice);
              }
              if (this.form.courses[x].standardShipping == true) {
                total_adds += parseFloat(this.form.courses[x].standardShippingPrice);
              }
              if (this.form.courses[x].fastShipping == true) {
                total_adds += parseFloat(this.form.courses[x].dayShippingPrice);
              }
              this.form.courses[x].total_total_ads = total_adds;

              total_total_ads += parseFloat(total_adds);
              subtotal += parseFloat(this.form.courses[x].price * this.form.courses[x].quantity);
              countquantity += parseInt(this.form.courses[x]['quantity']);
          }
          console.log(countquantity);
          this.form.total_adds = total_total_ads;
          this.form.subtotal = parseFloat(subtotal);

          if(this.form.coupons_id != null){
              this.form.total_discount = parseFloat( parseFloat(this.form.subtotal) * (this.coupon.percentage / 100) );
              this.form.percentage_discount = this.coupon.percentage;
          }
          else{
            this.form.total_discount = 0;
            this.form.percentage_discount = 0;
          }

          if (countquantity >= 3 && countquantity < 5) {
              var discount_aux = parseFloat( parseFloat(this.form.subtotal) * .20 );
              this.form.total_discount += parseFloat( discount_aux );
              this.form.percentage_discount = 20;
          }
          else if(countquantity >= 5){
              var discount_aux = parseFloat( parseFloat(this.form.subtotal) * .30 );
              this.form.total_discount += parseFloat( discount_aux );
              this.form.percentage_discount = 30;
          }
          this.form.total_discount = parseFloat( this.form.total_discount ).toFixed(2);

          this.form.total = parseFloat(parseFloat(this.form.subtotal) + parseFloat(this.form.total_adds) - parseFloat(this.form.total_discount)).toFixed(2);
        return this.form.total;
        }
  },
  methods: {
    getInfo(){
        if (this.$root.logged == true) {
          this.form.user = this.$root.user;
          this.form.cardName = this.form.user.nombre +' '+this.form.user.apellidos;
          if (this.$root.user.lastotder != null) {
              this.form.address = this.$root.user.lastotder.street;
              this.form.addressTwo = this.$root.user.lastotder.streetTwo;
              this.form.state_id = this.$root.user.lastotder.state_id;
              this.form.town = this.$root.user.lastotder.town;
              this.form.zip_code = this.$root.user.lastotder.zipcode;

          }
        }
    },
    onSubmit(event) {
      event.preventDefault();

      if(this.step == 2){
        //
      }

      if(this.step == 1){
        this.createOrder();

      }
    },
    getCourse(){
      axios.get(tools.url("/api/course/"+this.$route.params.id)).then((response)=>{
          this.course = response.data;
         this.form.courses_id = this.course.id;
        }).catch((error)=>{
        });
    },
    checkCoupon(){

      axios.post(tools.url("/api/checkcoupon"),{coupon:this.form.coupon}).then((response)=>{
          this.coupon = response.data;
          this.form.coupons_id = response.data.id;

          this.modal.block = false;
          this.modal.icon = "success";
          this.modal.msg = "Accepted code";
          this.$refs.modal.open();

        this.calculateTotals();
        }).catch((error)=>{
          this.modal.block = false;
          this.modal.icon = "error";
          this.modal.msg = error.response.data.msg;
          this.$refs.modal.open();
        });
    },
    getStates(){
      axios.get(tools.url('/api/statesstates')).then((response)=>{
        this.states = response.data;
      }).catch((error)=>{
         console.log(error);
      });
    },

    getTowns(){
      if(this.form.state_id){
        axios.get(tools.url('/api/towns/' + this.form.state_id)).then((response)=>{
          this.towns = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      }
    },
    createOrder: function(){
        this.modal.block = true;
        this.modal.icon = '';
        this.modal.msg = 'Processing, please wait...';
        this.$refs.modal.open();

        var formData = new FormData();

        formData.append('order', JSON.stringify(this.form));
        formData.append('user', JSON.stringify(this.form.user));
        formData.append('userLogin', this.$root.logged);

        if (typeof jQuery('input[name="image"]')[0].files[0] !== 'undefined') {
            formData.append("image", jQuery('input[name="image"]')[0].files[0]);
        }

        axios.post(tools.url('/api/order/store'),formData).then((response)=>{
            if(response.data.type === "success"){
              localStorage.removeItem('cart');
                this.order_id = response.data.order_id;
                this.$refs.modal.close();
                window.scrollTo(0, 0);
                //this.step = 2;
                this.$router.push('/thankyou/'+this.order_id);
            }else{
                this.$refs.modal.close();
                alert(response.data.error);
            }
        }).catch((error)=>{

            this.modal.icon="error";
            this.modal.msg = "Failed to place the order., Check your internet and reload the page";
            this.modal.block = false;


        });
    },
    getCart: function(){
      var storage = localStorage.getItem('cart');
      if(storage != null){
        var courses = JSON.parse(storage);
        axios.post(tools.url('/api/cart'),{ courses:courses }).then((response)=>{
          this.form.courses = response.data.cart;
          for (let z = 0; z < this.form.courses.length; z++) {
            if(this.form.courses[z].requiredId == 1){
                this.requiredId = true;
            }
            if(this.form.courses[z].requireBirthday == 1){
                this.requireBirthday = true;
            }

          }
          this.calculateTotals();
        }).catch((error)=>{
          console.log(error);
          this.getSteps();
        });
      }
    },
    getCartRefer(){
        var coursesdata = [];
        var courses = {
          'id':this.$route.query.id,
          'quantity':1
        };
        coursesdata.push(courses);
        axios.post(tools.url('/api/cart'),{ courses:coursesdata }).then((response)=>{
          this.form.courses = response.data.cart;
          this.calculateTotals();
        }).catch((error)=>{
          console.log(error);
          this.getSteps();
        });

    },
    calculateTotals(){
        var total_total_ads = 0;
          var subtotal = 0;
          var countquantity = 0;
          for (let x = 0; x < this.form.courses.length; x++) {
              var total_adds = 0;
              if (this.form.courses[x].narrator == true) {
                total_adds += parseFloat(this.form.courses[x].audioNarratorPrice);
              }
              if (this.form.courses[x].textemail == true) {
                total_adds += parseFloat(this.form.courses[x].alertPrice);
              }
              if (this.form.courses[x].standardShipping == true) {
                total_adds += parseFloat(this.form.courses[x].standardShippingPrice);
              }
              if (this.form.courses[x].fastShipping == true) {
                total_adds += parseFloat(this.form.courses[x].dayShippingPrice);
              }
              this.form.courses[x].total_total_ads = total_adds;

              total_total_ads += parseFloat(total_adds);
              subtotal += parseFloat(this.form.courses[x].price * this.form.courses[x].quantity);
              countquantity += parseInt(this.form.courses[x]['quantity']);
          }

          this.form.total_adds = total_total_ads;
          this.form.subtotal = parseFloat(subtotal);

          if(this.form.coupons_id != null){
              if(this.coupon.type == 'Porcentaje'){
                  this.form.total_discount = parseFloat( parseFloat(this.form.subtotal) * (this.coupon.quantity / 100) );
                  this.form.percentage_discount = this.coupon.quantity;
              }
              else if(this.coupon.type == 'Cantidad'){
                  this.form.total_discount = this.coupon.quantity;
                  this.form.percentage_discount = this.coupon.quantity;
              }

          }
          else{
            this.form.total_discount = 0;
            this.form.percentage_discount = 0;
          }
          if (countquantity >= 3 && countquantity < 5) {
              var discount_aux = parseFloat( parseFloat(this.form.subtotal) * .20 );
              this.form.total_discount += parseFloat( discount_aux );
              this.form.percentage_discount = 20;
          }
          else if(countquantity >= 5){
              var discount_aux = parseFloat( parseFloat(this.form.subtotal) * .30 );
              this.form.total_discount += parseFloat( discount_aux );
              this.form.percentage_discount = 30;
          }

          this.form.total_discount = parseFloat( this.form.total_discount ).toFixed(2);

          this.form.total = parseFloat(parseFloat(this.form.subtotal) + parseFloat(this.form.total_adds) - parseFloat(this.form.total_discount)).toFixed(2);
    },
    deleteCourse(indx){
      var newcourse = [];
      for (let x = 0; x < this.form.courses.length; x++) {
        if (x != indx) {
          newcourse.push(this.form.courses[x]);
        }
      }
      var cart = [];
      for (let x = 0; x < newcourse.length; x++) {

          cart.push({ id:newcourse[x].id, name:newcourse[x].name, quantity:newcourse[x].quantity });

      }

      this.form.courses = newcourse;
      localStorage.setItem("cart",JSON.stringify(cart));

      this.calculateTotals();
    },
    toHome(){
      this.modal.block = false;
      this.$refs.modal.close();
      this.$router.push('/');
    },

    noPaste(evt){
      event.preventDefault();
    },

    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    changeCantidad(type,index){
      //console.log(type,index);
      this.form.courses[index]['quantity'] = parseInt(this.form.courses[index]['quantity']);
      if (type == '-') {
        this.form.courses[index]['quantity'] = (this.form.courses[index]['quantity'] > 1) ? --this.form.courses[index]['quantity'] : 1;
      }
      else{
        this.form.courses[index]['quantity'] = (this.form.courses[index]['quantity'] < 100) ? ++this.form.courses[index]['quantity'] : 100;
      }

      this.form.courses[index]['quantity'] = this.form.courses[index]['quantity'].toString();
    },
    acceptNumber() {
        // var x = this.form.user.telefono.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        // this.form.user.telefono = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },


  },

  watch:{
    'showpass':function (val) {
      if (val == true) {
        document.getElementById("i-1").type="text";
      }
      else if (val == false) {
        document.getElementById("i-1").type="password";
      }
    },

    'showpasstwo':function (val) {
      if (val == true) {
        document.getElementById("i-2").type="text";
      }
      else if (val == false) {
        document.getElementById("i-2").type="password";
      }
    },
    'form.state_id':function (val) {
        this.getTowns();
    },
    /*form: {
      handler(val){
          var total_total_ads = 0;
          var subtotal = 0;
          for (let x = 0; x < this.form.courses.length; x++) {
              var total_adds = 0;
              if (this.form.courses[x].narrator == true) {
                total_adds += parseFloat(this.form.courses[x].audioNarratorPrice);
              }
              if (this.form.courses[x].textemail == true) {
                total_adds += parseFloat(this.form.courses[x].audioNarratorPrice);
              }
              if (this.form.courses[x].standardShipping == true) {
                total_adds += parseFloat(this.form.courses[x].audioNarratorPrice);
              }
              if (this.form.courses[x].fastShipping == true) {
                total_adds += parseFloat(this.form.courses[x].audioNarratorPrice);
              }
              this.form.courses[x].total_total_ads = total_adds;

              total_total_ads += total_adds;
              subtotal += this.form.courses[x].price;
          }

          this.form.total_adds = total_total_ads;
          this.form.subtotal = parseFloat(subtotal);
          /*var total_adds = 0;
          if (this.form.narrator == true) {
            total_adds += parseFloat(this.course.audioNarratorPrice);
          }
          if (this.form.textemail == true) {
            total_adds += parseFloat(this.course.alertPrice);
          }
          if (this.form.standardShipping == true) {
            total_adds += parseFloat(this.course.standardShippingPrice);
          }
          if (this.form.fastShipping == true) {
            total_adds += parseFloat(this.course.dayShippingPrice);
          }
          this.form.total_adds = total_adds;*+/

          if(this.form.coupons_id != null){
              this.form.total_discount = parseFloat( parseFloat(this.form.subtotal) * (this.coupon.percentage / 100) );
          }
          else{
            this.form.total_discount = 0;
          }

          this.form.total = parseFloat(this.form.subtotal) + parseFloat(this.form.total_adds) - this.form.total_discount;


      },
      deep: true
    }*/

  },

  mounted(){
    if (this.$route.query.id) {
      this.getCartRefer();
      this.form.refer = this.$route.query.refer;
      this.form.user.email = this.$route.query.email;
    }
    else{
      this.getCart();
    }


    var self = this;
    setTimeout(function(){
        self.getInfo();

    }, 1000);

    setTimeout(function(){

        self.disabledQuantity = false;
    }, 1500);

    this.getStates();
    this.currentYear = new Date().getFullYear();

  }
}
</script>

<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
			<h5>Be the first to know!</h5>
      <h2>Sign in to our Newsletter</h2>

      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Type your email"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Subscribe</b-button>
      </b-form>
    </section>

  	<section class="footer-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-9">
            <div class="row">
              <div class="col-6 col-lg-4 col-footer">
                <h5>General</h5>

                <p class="f-w-300"><router-link class="link-footer" to="/">Home</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/courses">Courses</router-link></p>
								<p class="mt-lg-1"><router-link class="link-footer" to="/about">About us</router-link></p>
								<p class="mt-lg-1"><router-link class="link-footer" to="/faq">FAQ</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/blogs">Blog</router-link></p>
              </div>

              <div class="col-6 col-lg-4 col-footer">
                <h5>Help links</h5>

                <p class="f-w-300"><router-link class="link-footer" to="/contact">Contact us</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/discounts">Corporate discounts</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/access/sign-up">Create an account</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/access">Log in</router-link></p>
								<!-- <p class="mt-lg-1"><router-link class="link-footer" to="#">Customer service</router-link></p> -->
                <!-- <p class="mt-lg-1"><router-link class="link-footer" to="#">Report a problem</router-link></p> -->
              </div>

              <div class="col-lg-4 col-footer">
                <h5>Information</h5>

                <p class="mt-lg-1"><router-link class="link-footer" to="/terms">Terms and conditions</router-link></p>

                <p class="mt-2 copy-text">
                  Copyright {{ currentYear }} 1 Day Courses
                </p>
              </div>
            </div>
          </div>


          <div class="col-lg-3 col-footer col-copyright align-self-center">
            <p class="mb-3">
              <!-- <router-link class="t-150 btn-network" to="/contacto">
                <span><i class="fal fa-envelope"></i></span>
              </router-link> -->
              <!-- <a class="t-150 btn-network" target="_blank" href="#">
                <span><i class="fab fa-instagram"></i></span>
              </a> -->
              <!-- <a class="t-150 btn-network" target="_blank" href="#">
                <span><i class="fab fa-twitter"></i></span>
              </a> -->
							<!-- <a class="t-150 btn-network" target="_blank" href="#">
								<span><i class="fab fa-facebook-f"></i></span>
							</a> -->
              <!-- <a class="t-150 btn-network" target="_blank" href="#">
                <span><i class="fab fa-linkedin-in"></i></span>
              </a> -->
            </p>

            <p class="p-sm p-icon i-letter">
              info@1daycourses.com
            </p>
            <p class="mt-3 p-sm p-icon i-phone">
              1-800-292-6120
            </p>
            <p class="mt-3 p-sm p-icon i-map">
              5444 Westheimer Rd Suite 1000,<br />
							Houston, TX 77056
            </p>
          </div>
  			</div>
      </div>
  	</section>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      currentYear: 0,

      form: {
        email: null
      },
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("Do you want to register for the newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          alert(response.data.msg);
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
  },

  beforeMount() {
    this.currentYear = new Date().getFullYear();
  }
}
</script>

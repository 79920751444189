<template>
  <div id="home-page">

    <section class="container-fluid banner-section">
      <div v-swiper="bannersOptions" class="swiper swiper-desktop" v-if="banners.pc.length" :instanceName="'carrusel-bann1'" :loadtheme="false">
        <div class="swiper-wrapper">
          <div class="swiper-slide"  v-for="(banner, bdInx) in banners.pc" :key="'bdInx-'+bdInx">
            <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }" :href="banner.url">
              <img src="/public/images/pages/home/banner.png">
            </a>
          </div>
        </div>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>

      <div v-swiper="bannersOptions" class="swiper swiper-mobile" v-if="banners.movil.length" :instanceName="'carrusel-bann2'" :loadtheme="false">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(banner, bmInx) in banners.movil" :key="'bmInx-'+bmInx">
            <a class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }" :href="banner.url">
              <img src="/public/images/pages/home/banner-m.png">
            </a>
          </div>
        </div>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </div>

      <div class="box-content">
        <div class="container oversized-container">
          <div class="row">
            <b-form class="col-12 col-form-y" @submit="onSubmit">
              <h6 class="title">Our Courses are Approved by TDLR.</h6>

              <div class="d-block">
                <span class="box-title">SELECT YOUR COURSE</span>
              </div>

              <div class="box-select">
                <b-form-select v-model="formCourses.state">
                  <b-form-select-option :value="null" disabled>Select a state</b-form-select-option>
                  <b-form-select-option :value="s.id" v-for="(s,indx) in states" :key="indx">{{ s.name }}</b-form-select-option>
                </b-form-select>
              </div>

              <div class="box-select" v-bind:class="{ disabled: disabledSelect == true, loading: loadingSelect == true }">
                <div class="bx">
                  <b-form-select v-model="formCourses.course">
                    <b-form-select-option :value="null" disabled>Select your course</b-form-select-option>
                    <b-form-select-option :value="c.id" v-for="(c,indx) in coursesstates" :key="indx">{{ c.name }}</b-form-select-option>
                  </b-form-select>
                </div>
              </div>

              <p class="mt-3">
                <b-button type="submit" class="btn-send">START NOW</b-button>
              </p>
            </b-form>
          </div>
        </div>
      </div>
    </section>

    <section class="info-s1-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <!-- <div class="col-lg-3 col-about-us">
            <h2>Our number tell more about us</h2>
          </div> -->

          <div class="col-md-6 col-lg-3 col-info">
            <p class="mb-2 text-center">
              <img class="icon" src="/public/images/pages/home/icon-1.svg" alt="Serving Customers since 2015">
            </p>
            <!-- <h3 class="number">1</h3> -->
            <p>
              Serving Customers since 2015
            </p>
          </div>

          <div class="col-md-6 col-lg-3 col-info">
            <p class="mb-2 text-center">
              <img class="icon" src="/public/images/pages/home/icon-2.svg" alt="Over 217,841 Licenses Renewed">
            </p>
            <!-- <h3 class="number">2</h3> -->
            <p>
              Over 217,841 Licenses Renewed
            </p>
          </div>

          <div class="col-md-6 col-lg-3 col-info">
            <p class="mb-2 text-center">
              <img class="icon" src="/public/images/pages/home/icon-3.svg" alt="Secure Checkout">
            </p>
            <!-- <h3 class="number">3</h3> -->
            <p>
              Secure Checkout
            </p>
          </div>

          <div class="col-md-6 col-lg-3 col-info">
            <p class="mb-2 text-center">
              <img class="icon" src="/public/images/pages/home/icon-4.svg" alt="Instant Certificate of Completion">
            </p>
            <!-- <h3 class="number">4</h3> -->
            <p>
              Instant Certificate of Completion
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- <WhyComponent></WhyComponent> -->

    <section class="container oversized-container courses-section">
      <div class="row">
        <div class="col-12 text-center col-title">
          <h2 class="sec-title">Our courses</h2>
        </div>

        <div class="col-12 col-carousel">
          <swiper class="swiper swiper-desktop" :options="coursesOptions">
            <swiper-slide v-for="(c, chInx) in courses" :key="'chInx-'+chInx">
              <div class="col-12 px-0 box-courses-sample-1">
                <router-link class="box" :to="'/courses/index/'+c.custom_url">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+c.image_url+')' }">
                    <img src="/public/images/pages/courses/course.png">
                  </div>

                  <div class="box-name" v-bind:style="{ backgroundColor: c.hexColor }">
                    <div class="inside">
                      <h6>{{ c.name }}</h6>
                    </div>
                  </div>
                </router-link>
              </div>
            </swiper-slide>


            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="reasons-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center col-title">
            <h2 class="sec-title">Key Features</h2>
          </div>

          <div class="col-lg-4 col-reason">
            <p class="p-icon">
              <img src="/public/images/pages/home/reason-1.png">
            </p>
            <h2 class="sec-title">Finish fast</h2>
            <p>
              Shortest courses allowed by the states
            </p>

            <hr class="line" />
          </div>

          <div class="col-lg-4 col-reason">
            <p class="p-icon">
              <img src="/public/images/pages/home/reason-2.png">
            </p>
            <h2 class="sec-title">Instant certificate</h2>
            <p>
              Emailed instantly at completion
            </p>

            <hr class="line" />
          </div>

          <div class="col-lg-4 col-reason">
            <p class="p-icon">
              <img src="/public/images/pages/home/reason-3.png">
            </p>
            <h2 class="sec-title">Easy online payment</h2>
            <p>
              Multiple secure payment options
            </p>

            <hr class="line" />
          </div>

          <router-link class="col-lg-4 col-reason" to="/contact">
            <p class="p-icon">
              <img src="/public/images/pages/home/reason-4.png">
            </p>
            <h2 class="sec-title">Customer service</h2>
            <p>
              Text or call us today!
            </p>

            <hr class="line" />
          </router-link>

          <div class="col-lg-4 col-reason">
            <p class="p-icon">
              <img src="/public/images/pages/home/reason-5.png">
            </p>
            <h2 class="sec-title">Any device</h2>
            <p>
              Phone, tablet or PC
            </p>

            <hr class="line" />
          </div>

          <div class="col-lg-4 col-reason">
            <p class="p-icon">
              <img src="/public/images/pages/home/reason-6.png">
            </p>
            <h2 class="sec-title">At your pace</h2>
            <p>
              Log in and Out Anytime
            </p>

            <hr class="line" />
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="container oversized-container how-section">
      <div class="row">
        <div class="col-lg col-image">
          <img src="/public/images/pages/home/how.jpg">
        </div>

        <div class="col-lg col-info">
          <div class="box-title">
            <h5 class="sec-title">How does it work?</h5>

            <p>
              1 Day Courses helps you obtain a certificate for one of our 7 courses.
            </p>
          </div>

          <div class="row box-steps">
            <div class="col-sm-4 col-step">
              <div class="box-line">
                <i class="icon"></i>
              </div>

              <div class="box-text">
                <p>Create your account and select the course you want</p>
              </div>
            </div>

            <div class="col-sm-4 col-step">
              <div class="box-line">
                <i class="icon"></i>
              </div>

              <div class="box-text">
                <p>Complete the course at your own pace and time.</p>
              </div>
            </div>

            <div class="col-sm-4 col-step">
              <div class="box-line">
                <i class="icon"></i>
              </div>

              <div class="box-text">
                <p>Submit your results, we will review it and give your certificate.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="how-section-v2">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg col-info">
            <div class="box-title">
              <h5 class="text-center sec-title">How does it work?</h5>

              <!-- <p>
                1 Day Courses helps you obtain a certificate for one of our 7 courses.
              </p> -->
            </div>

            <div class="row box-steps">
              <div class="col-sm-4 col-step">
                <div class="box-line s-1">
                  <!-- <i class="icon"></i> -->
                </div>

                <div class="box-text">
                  <h5 class="s-title">1. SELECT YOUR COURSE</h5>
                  <p>Select the course you need.</p>
                </div>
              </div>

              <div class="col-sm-4 col-step">
                <div class="box-line s-2">
                  <!-- <i class="icon"></i> -->
                </div>

                <div class="box-text">
                  <h5 class="s-title">2. COMPLETE IT!</h5>
                  <p>Complete your course "At Your Own Pace"</p>
                </div>
              </div>

              <div class="col-sm-4 col-step">
                <div class="box-line s-3">
                  <!-- <i class="icon"></i> -->
                </div>

                <div class="box-text">
                  <h5 class="s-title">3. RENEW YOUR LICENSE!</h5>
                  <p>We will submit your credits upon completion.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="contact-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-text-1">
            <h6>Contact us</h6>
            <h4><router-link to="/contact">Send us a message</router-link></h4>
          </div>

          <div class="col-lg-6 col-text-2">
            <h5>Talk or send us a text <a class="f-w-700" href="tel:13239649438">1-800-292-6120</a></h5>
          </div>

          <!-- <div class="col-lg-4 col-text-3">
            <div class="col col-label">
              <h5>Follow us in our social media</h5>
            </div>

            <div class="col col-network">
              <a class="t-250 btn-in" href="#"><span><i class="fab fa-instagram"></i></span></a>
              <a class="t-250 btn-fb" href="#"><span><i class="fab fa-facebook-f"></i></span></a>
              <span class="onestop">@1daycourses.com</span>
            </div>
          </div> -->
        </div>
      </div>
    </section>

    <b-modal modal-class="modal-add-ons" ref="modal-checkout" size="lgs" title="Secure Checkout" centered hide-footer>
      <div class="d-block">
        <p>
          At Comedy Defensive Driving, we use Authorize.Net, so you can be confident your data and personal information is secure. Online payments utilize industry-leading technologies and protocols, such as 128-bit Secure Sockets Layer (SSL), and are compliant with a number of government and industry security initiatives. We take your privacy very seriously, and do our best to keep our systems secure so that you can comfortably focus on completing your course and getting that traffic ticket dismissed.
        </p>
      </div>
    </b-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: {
        pc:[],
        movil:[]
      },

      disabledSelect: true,
      loadingSelect: false,

      courses: [
        { imageUrl: 'public/images/pages/courses/course-1.jpg', hexColor: '#ce0052', name: 'Cosmetology' },
        { imageUrl: 'public/images/pages/courses/course-2.jpg', hexColor: '#913fd9', name: 'Electrical' },
        { imageUrl: 'public/images/pages/courses/course-3.jpg', hexColor: '#4700ce', name: 'HVAC / Air Conditioning' },
        { imageUrl: 'public/images/pages/courses/course-4.jpg', hexColor: '#0076ff', name: 'Tow Operator' },
        { imageUrl: 'public/images/pages/courses/course-5.jpg', hexColor: '#68a524', name: 'Property tax' },

        { imageUrl: 'public/images/pages/courses/course-1.jpg', hexColor: '#ce0052', name: 'Cosmetology' },
        { imageUrl: 'public/images/pages/courses/course-2.jpg', hexColor: '#913fd9', name: 'Electrical' },
        { imageUrl: 'public/images/pages/courses/course-3.jpg', hexColor: '#4700ce', name: 'HVAC / Air Conditioning' },
        { imageUrl: 'public/images/pages/courses/course-4.jpg', hexColor: '#0076ff', name: 'Tow Operator' },
        { imageUrl: 'public/images/pages/courses/course-5.jpg', hexColor: '#68a524', name: 'Property tax' },
      ],

      formCourses: {
        state: null,
        course: null,
      },

      // == Carousels options ==
      bannersOptions: {
        slidesPerView: 1,
        loop: true,
        speed: 750,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      coursesOptions: {
        slidesPerView: 5,
        spaceBetween: 25,
        loop: true,
        speed: 750,
        centeredSlides: true,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1500: {
            slidesPerView: 5,
          },
          1200: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },
      // == ==
      categories:[],
      courses:[],
      states:[],
      coursesstates:[]
    }
  },
  watch:{
    'formCourses.state':function(){
      this.getCoursesState();
    }
  },
  methods: {
    getHome(){
      axios.get(tools.url("/api/home")).then((response)=>{
          this.banners = response.data.banners;
          this.courses = response.data.courses;
          this.states = response.data.states;
        }).catch((error)=>{
      });
    },

    getCoursesState(){
      this.disabledSelect = true;
      this.loadingSelect = true;

      axios.get(tools.url("/api/coursesstate/"+this.formCourses.state)).then((response)=>{
          this.coursesstates = response.data;

          setTimeout(() => {
            this.disabledSelect = false;
            this.loadingSelect = false;
          }, 1000);
        }).catch((error)=>{
      });
    },

    onSubmit(event) {
      event.preventDefault();
      var couursedata = null;
      for (let x = 0; x < this.coursesstates.length; x++) {
          if(this.formCourses.course == this.coursesstates[x].id){
            couursedata = this.coursesstates[x];
            break;
          }
      }
      this.$router.push('/courses/checkout/'+couursedata.state+'/'+couursedata.custom_url);
    }
  },

  mounted(){
    this.getHome();

    this.$ga.page('Home'); // Puedes establecer el nombre de la página actual

  },
}
</script>

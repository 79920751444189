<template lang="html">
  <div id="course-description-page">

    <section class="placed-backg banner-section" v-bind:style="{ backgroundImage: 'url(public/images/pages/courses/banner.jpg)' }">
      <div class="inside">
        <h1>{{course.name}}</h1>

        <a class="t-250 btn-enroll" @click="addCart" style="cursor: pointer;">Enroll now! ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.price) }} USD</a>
      </div>
    </section>

    <section class="container description-section">
      <div class="row" v-if="!loading">
        <!-- <div class="col-12 col-descr">
          <div class="img" style="width: 44%;">
            <img class="placed-backg" src="/public/images/pages/courses/img-course.png" v-bind:style="{ backgroundImage: 'url('+ course.image_url + ')' }">
          </div>

          <h5 class="subtitle">Course price</h5>
          <h4 class="price">${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.price) }} USD</h4>

          <h5 class="mt-3 subtitle">Course Details</h5>
          <div class="box-descr" v-html="course.description">

          </div>
        </div>

        <div class="col-6 mt-3 mt-sm-2 col-descr" style="word-wrap: break-word">
          <div class="row">
            <div class="col-lg-8">
              <h5 class="subtitle">Course description</h5>
              <div v-html="course.detail">

              </div>
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 mt-sm-2 col-descr" style="word-wrap: break-word">
          <div class="row">
            <div class="col-lg-8">
              <h5 class="subtitle">Features</h5>
              <div v-html="course.character">

              </div>
            </div>
          </div>
        </div> -->

        <div class="col-md-8 col-descr col-left">
          <div class="row">
            <div class="col-lg-12">
              <h5 class="subtitle">Course description</h5>
              <div v-html="course.detail">

              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <h5 class="mt-3 subtitle">Course Details</h5>
                <div class="box-descr" v-html="course.description">

              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-12 text-center">
              <div class="img">
                <img class="placed-backg" src="/public/images/pages/courses/img-course.png" v-bind:style="{ backgroundImage: 'url('+ course.image_url + ')' }" style="background-size: contain !important;">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-descr col-right">
          <div class="row">
            <div class="col-lg-12">
              <div class="img" style="width: 80%;">
                <img class="placed-backg" src="/public/images/pages/courses/img-course.png" v-bind:style="{ backgroundImage: 'url('+ course.imagestate_url + ')' }">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12">
              <h5 class="subtitle">Features</h5>
              <div v-html="course.character">

              </div>
            </div>
          </div>
        </div>


        <div class="col-12 mt-3 mt-sm-2 px-lg-0 col-descr" style="cursor: pointer;">
          <a class="t-250 btn-enroll-now" @click="addCart">Enroll now! ${{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(course.price) }} USD</a>
        </div>
      </div>
    </section>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modal">
        <div v-html="modal.msg"></div>
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
            <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close()">Add more courses</b-button>
            <b-button class="btn btn-success" slot="button" v-on:click.prevent="$refs.modal.close(); $router.push('/enroll')">Go to cart <i class="color-white fas fa-shopping-cart"></i></b-button>
        </div>
    </sweet-modal>

    <sweet-modal ref="modalLoading">
      <div class="fa-3x"><i class="fas fa-spinner fa-pulse"></i></div><br>
      <p>Loading ...</p>
    </sweet-modal>

  </div>
</template>

<script>
export default {

  data(){
    return{
      course: {
        page_title:'1 Day Courses',
        price:0
      },
      modal:{
        msg:'',
        icon:'',
        block:false
      },
      id:null,
      loading:true

    }

  },
   //insert the following code for vue-meta to work
   metaInfo() {
      return {
            title: this.course.page_title + ' | 1 Day Courses',
            meta: [
              { name: 'title', content: this.course.meta_title},
                { name: 'description', content: this.course.description},
                { name: 'keywords', content: this.course.keywords},
                { name: 'schema', content: this.course.meta_schema},

            ]
        }
  },

  watch:{
    '$route.params.id':function(){
      this.id = this.$route.params.id,
      this.getCourse();
    }
  },

  methods: {
    getCourse(){
      this.loading = true;
      this.$refs.modalLoading.open();
      axios.get(tools.url("/api/course/"+this.id)).then((response)=>{
          this.course = response.data;
          this.$refs.modalLoading.close();
          this.loading = false;
        }).catch((error)=>{
          this.$refs.modalLoading.close();
          this.loading = false;
        });
    },

    addCart(){
      var cart = [];
      var encontrado = false;

      if(localStorage.cart){
        cart = JSON.parse(localStorage.getItem('cart'));

        for(var i = 0; i < cart.length ; i++){
          if(cart[i].id == this.course.id && cart[i].name == this.course.name ){
            var suma = cart[i].quantity + 1;
            cart[i].quantity = suma;
            encontrado = true;
            break;
          }
        }

        if(!encontrado){
          cart.push({ id:this.course.id, name:this.course.name, quantity:1 });
        }
      } else{
        cart.push({ id:this.course.id, name:this.course.name, quantity:1});
      }
      localStorage.setItem("cart",JSON.stringify(cart));
      this.$root.cartCount = cart.length;
      //this.toast();
      this.modal.msg = '<b>'+this.course.name+'</b> course added to cart';
      this.modal.icon = 'success';
      this.$refs.modal.open();
/*
        const orderData = {
          id: 'test',
          affiliation: 'Richatd',
          revenue: 100, // Ingresos generados por la transacción
          shipping: 10, // Costo de envío
          tax: 5, // Impuesto
          products: [
            {
              id: 'ID_producto_1',
              name: 'Nombre_producto_1',
              price: 50, // Precio del producto
              quantity: 2, // Cantidad comprada
            },
            {
              id: 'ID_producto_2',
              name: 'Nombre_producto_2',
              price: 30, // Precio del producto
              quantity: 1, // Cantidad comprada
            },
            // Puedes incluir más productos si es necesario
          ],
        };

        // Envía la información de la transacción como un evento de comercio electrónico
        //this.$ga.ecommerce(orderData);

        this.$ga.event('ecommerce', 'purchase', JSON.stringify(orderData));
*/
        const item = {
          id:  this.id ,
          name: this.course.name,

          price: this.course.price, // Precio del producto
          quantity: 1, // Cantidad agregada al carrito
        };

        // Envía la información del producto agregado al carrito como un evento personalizado
        this.$ga.event('ecommerce', 'add_to_cart', JSON.stringify(item));


    },

  },

  mounted(){
    this.id = this.$route.params.id,

    this.getCourse();
  },
}
</script>

<template lang="html">
  <div class="mt-3 mt-lg-0 main-content">

    <div class="box-top">
      <div class="box-title">
        <div class="col col-title">
          <h6 class="title">{{ module.title }}</h6>
        </div>

        <div class="col col-counter" v-if="$parent.course.timers_on == 1">
          <countdown class="box-counter" :time="module.timeminms" :auto-start="true" ref="countdown" @progress="handleCountdownProgressMin" :transform="transformMin" @end="inProgressMin = false">
            <template slot-scope="props">
              <div class="col col-time">
                <span class="time">{{ props.hours }}</span>
                <small>Hr</small>
              </div>

              <div class="col col-sep">:</div>

              <div class="col col-time">
                <span class="time">{{ props.minutes }}</span>
                <small>Min</small>
              </div>

              <div class="col col-sep">:</div>

              <div class="col col-time">
                <span class="time">{{ props.seconds }}</span>
                <small>Sec</small>
              </div>
            </template>
          </countdown>


          
        </div>
      </div>
      <div class="box-title" v-if="$parent.course.hasAudioNarrator == 1">
          <div class="col">
            <a style="font-size: 40px;color:white;padding-right: 10px;cursor: pointer;" @click="playVoice" v-if="isplaying == false"><i class="fas fa-play-circle"></i></a>
            <a style="font-size: 40px;color:white;padding-right: 10px;cursor: pointer;" @click="pauseVoice" v-if="isplaying == true" ><i class="fas fa-pause-circle"></i></a>
            
            <a style="font-size: 40px;color:white;cursor: pointer;" @click="cancelVoice"><i class="fas fa-stop-circle"></i></a>
            
            
          </div>
      </div>


      <!-- <div class="box-pages">
        <div class="col-lg-4 col-page">
          <router-link class="" :to="{ name: 'mCoursePage', params: { course_id: 1, module_id: 1} }">Curso de Consulta</router-link>
        </div>
        <div class="col-lg-4 col-page">
          <router-link :to="{ name: 'mExercicesPage', params: { course_id: 1, module_id: 1} }">Ejercicios</router-link>
        </div>
        <div class="col-lg-4 col-page">
          <router-link :to="{ name: 'mSupportPage', params: { course_id: 1, module_id: 1} }">Referencias de Apoyo</router-link>
        </div>
        <div class="col-lg-3 col-page">
          <router-link :to="{ name: 'mInboxPage', params: { course_id: 1, module_id: 1} }">Inbox al Profesor</router-link>
        </div>
      </div> -->
    </div>

    <div id="u-course-page" v-if="module.subs.length > 0">
      <div class="_box-module-content">
        <div class="box-body">
          <div class="box-steps" id="box-steps">
            <a v-if="step > 0" class="next" style="margin-right: 18px;" @click="goBefore" ><i class="fas fa-arrow-alt-left"></i></a>
            <a>{{ step + 1 }}</a>
            <span class="sep">/</span>
            <a>{{ module.subs.length }}</a> 
            <a class="next" @click="goNext" v-if="!showExamBTN"><i class="fas fa-arrow-alt-right"></i></a>
          </div>
        </div>
      </div>

      <!-- <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/user-account/course.jpg)' }"> -->
      <!-- <div class="box-tab-videos" v-if="videosID.length > 0">
        <a class="trans-250 tab" v-bind:class="{ 'active' : currentVidInx == tabvInx }" v-for="(v, tabvInx) in videosID" :key="'tabvInx-'+tabvInx" @click="currentVidInx = tabvInx">Video {{ tabvInx + 1 }}</a>
      </div> -->

      <div class="_box-module-content" v-if="step == 0 && module.position == 1 && $parent.course.personal_questions == 1">
          <div class="question-answer">
            <h5 class="question">1.- Which of the following is your favorite color?</h5>
            <b-form-group class="answers">
              <b-form-radio v-model="form_quiz.answer_1" :name="'question1'" value="Blue">Blue</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_1" value="Red">Red</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_1" value="Pink">Pink</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_1" value="Purple">Purple</b-form-radio>
            </b-form-group>
          </div>

          <div class="question-answer">
            <h5 class="question">2.- Which of the following is your favorite season in the year?</h5>
            <b-form-group class="answers">
              <b-form-radio v-model="form_quiz.answer_2" :name="'question2'" value="Winter">Winter</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_2" value="Spring">Spring</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_2" value="Fall">Fall</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_2" value="Summer">Summer</b-form-radio>
            </b-form-group>
          </div>

          <div class="question-answer">
            <h5 class="question">3.- Which of the following is your favorite type of vehicle?</h5>
            <b-form-group class="answers">
              <b-form-radio v-model="form_quiz.answer_3" :name="'question3'" value="Sports car">Sports car</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_3" value="Pickup Truck">Pickup Truck</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_3" value="SUV (Sport Utility Vehicle)">SUV (Sport Utility Vehicle)</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_3" value="Sedan">Sedan</b-form-radio>
            </b-form-group>
          </div>


          <div class="question-answer">
            <h5 class="question">4.- Which of the following is your favorite type of food?</h5>
            <b-form-group class="answers">
              <b-form-radio v-model="form_quiz.answer_4" :name="'question4'" value="Italian">Italian</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_4" value="Mexican">Mexican</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_4" value="Mediterranean">Mediterranean</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_4" value="Japanese">Japanese</b-form-radio>
            </b-form-group>
          </div>

          <div class="question-answer">
            <h5 class="question">5.- Which of the following is your favorite Sport?</h5>
            <b-form-group class="answers">
              <b-form-radio v-model="form_quiz.answer_5" :name="'question5'" value="Football">Football</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_5" value="Basketball">Basketball</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_5" value="Baseball">Baseball</b-form-radio>
              <b-form-radio v-model="form_quiz.answer_5" value="Soccer">Soccer</b-form-radio>
            </b-form-group>
          </div>
      </div>

      
      
      <div class="box-videos" v-if="module.subs[step].video != null">
        <div class="video">
          <!-- <iframe :src="formatLink(vID)" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> -->

          <video width="100%" height="300" controls >
            <source :src="module.subs[step].video" type="video/mp4">
            <source src="movie.ogg" type="video/ogg">
          Your browser does not support the video tag.
          </video>
          <!-- <youtube :video-id="vID"></youtube> -->
        </div>
      </div>

      <div class="_box-module-content">
        <div style="text-align: center;">
          <img  :src="module.subs[step].image">
        </div>
        
        <!-- <h5 class="title">{{ module.subs[step].title }}</h5> <small></small>
        <div class="text-justify" v-if="module.subs[step].videoUrl">{{ module.subs[step].videoUrl }}</div><hr> -->

        <div class="box-body">
          <div class="text-justify" v-html="module.subs[step].body"></div>

          <div v-if="$parent.course.personal_questions == 1">
            <div class="question-answer" v-if="step + 1 == module.subs.length && module.position == 2">
                <h5 class="question">1.- Which of the following is your favorite color?</h5>
                <b-form-group class="answers">
                    <b-form-radio v-model="form_answer.answer_1" :name="'answer_1'" value="Blue">Blue</b-form-radio>
                    <b-form-radio v-model="form_answer.answer_1" value="Red">Red</b-form-radio>
                    <b-form-radio v-model="form_answer.answer_1" value="Pink">Pink</b-form-radio>
                    <b-form-radio v-model="form_answer.answer_1" value="Purple">Purple</b-form-radio>
                </b-form-group>
            </div>
            <div class="question-answer" v-if="step + 1 == module.subs.length && module.position == 3">
              <h5 class="question">2.- Which of the following is your favorite season in the year?</h5>
              <b-form-group class="answers">
                  <b-form-radio v-model="form_answer.answer_2" :name="'answer_2'" value="Winter">Winter</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_2" value="Spring">Spring</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_2" value="Fall">Fall</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_2" value="Summer">Summer</b-form-radio>
              </b-form-group>
            </div>
            <div class="question-answer" v-if="step + 1 == module.subs.length && module.position == 4">
              <h5 class="question">3.- Which of the following is your favorite type of vehicle?</h5>
              <b-form-group class="answers">
                  <b-form-radio v-model="form_answer.answer_3" :name="'answer_3'" value="Sports car">Sports car</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_3" value="Pickup Truck">Pickup Truck</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_3" value="SUV (Sport Utility Vehicle)">SUV (Sport Utility Vehicle)</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_3" value="Sedan">Sedan</b-form-radio>
              </b-form-group>
            </div>
            <div class="question-answer" v-if="step + 1 == module.subs.length && module.position == 5">
              <h5 class="question">4.- Which of the following is your favorite type of food?</h5>
              <b-form-group class="answers">
                  <b-form-radio v-model="form_answer.answer_4" :name="'answer_4'" value="Italian">Italian</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_4" value="Mexican">Mexican</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_4" value="Mediterranean">Mediterranean</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_4" value="Japanese">Japanese</b-form-radio>
              </b-form-group>
            </div>
            <div class="question-answer" v-if="step + 1 == module.subs.length && module.position == 6">
              <h5 class="question">5.- Which of the following is your favorite Sport?</h5>
              <b-form-group class="answers">
                  <b-form-radio v-model="form_answer.answer_5" :name="'answer_5'" value="Football">Football</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_5" value="Basketball">Basketball</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_5" value="Baseball">Baseball</b-form-radio>
                  <b-form-radio v-model="form_answer.answer_5" value="Soccer">Soccer</b-form-radio>
              </b-form-group>
            </div>
          </div>

          <div class="box-steps" id="box-steps">
            <a v-if="step > 0" class="next" style="margin-right: 18px;" @click="goBefore" ><i class="fas fa-arrow-alt-left"></i></a>

            <a>{{ step + 1 }}</a>
            <span class="sep">/</span>
            <a>{{ module.subs.length }}</a> 

            
            <a class="next" @click="goNext" v-if="!showExamBTN"><i class="fas fa-arrow-alt-right"></i></a>
          </div>
        </div>

        <a v-if="module.subs[step].pdf" @click="openRefModal(module.subs[step].pdf)"><span class="btn btn-red-" ><i class="fas fa-download"></i></span></a>
        <a v-if="module.subs[step].audio" :href="module.subs[step].audio" target="_blank" ><span class="btn btn-red-" ><i class="fas fa-file-audio"></i></span></a>
      
      </div>
    </div>
    <div v-if="showExamBTN" >
        <div id="box-course-btns" class="text-center" v-if="inProgressMin == false">
          <b-button v-if="module.hastest" type="button" class="btn-red- btn-course-action" @click="applyExam">Finish and Take Quiz</b-button>

          <b-button v-else type="button" class="btn-red- btn-course-action" @click="nextModule">
            <span v-if="module.subs.length > 0">
              <span v-if="step + 1 == module.subs.length">Finish Section</span> 
              <span v-else>Next module</span>
            </span>
            <span v-else>
              <span>Finish Section</span> 
            </span>
            
          </b-button>
        </div>
        <div id="box-course-btns" class="text-center" v-else>
          <b-button v-if="module.hastest" type="button" class="btn-course-action" @click="openModalWait()" >Finish and Take Quiz</b-button>

          <b-button v-else type="button" class="btn-course-action" @click="openModalWait()">
            <span v-if="module.subs.length > 0">
              <span v-if="step + 1 == module.subs.length">Finish Section</span> 
              <span v-else>Next module</span>
            </span>
            <span v-else>
              <span>Finish Section</span> 
            </span>
            
          </b-button>
        </div>
      </div>

    <b-modal modal-class="modal-reference" ref="modal-reference" size="xl" title="Reference" centered hide-footer no-close-on-backdrop no-close-on-esc>
      <div class="d-block loading">
        <iframe :src="urlFile"></iframe>
      </div>
    </b-modal>
    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block"  ref="modalloadinfg">
          <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
          <div v-html="modal.msg"></div>

    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      step: 0,
      showExamBTN: false,

      // == Counter ==
      inProgress: true, // "true" hasta que el contador llegue a 0, en ese caso pasa a "false"
      //hours: 1,     // Debe ser MENOR o IGUAL a 24 y MAYOR o IGUAL A 1
      //minutes: 60,  // Debe ser MENOR o IGUAL a 60 y MAYOR o IGUAL A 1
     

      counterHours: 0,
      counterMinutes: 0,
      counterSeconds: 0,
      // == Counter -end ==

      inProgressMin:true,
      counterHoursMin: 0,
      counterMinutesMin: 0,
      counterSecondsMin:0,

      course_id: null,
      module_id: null,
      urlFile: null,

      module: {
        hours:0,
        minutes: 0,
        subs:[],
        timemaxms:0,
        timeminms:0
      },
      videosID: [
        
      ],
      currentVidInx: 0,
      index: null,

      modal:{
        msg:'',
        icon:'',
        block:false,
        time:false
      },
      isplaying:false,
      ispaused:false,
      refreshInterval:null,
      form_quiz:{
          answer_1:null,
          answer_2:null,
          answer_3:null,
          answer_4:null,
          answer_5:null,
      },
      form_answer:{
          answer_1:null,
          answer_2:null,
          answer_3:null,
          answer_4:null,
          answer_5:null,
      }
    }
  },

  methods: {
    // == Counter ==
    /*handleCountdownProgress(data) {
      
      this.counterHours = data.hours;
      this.counterMinutes = data.minutes;
      this.counterSeconds = data.seconds;
    },

    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;

        props[key] = `${digits}`
      });

      return props;
    },
    saveProgress(){
      // Guardar el progreso (horas y minutos) cada 10 segundos
      let hours;
      let minutes;

      var refreshInterval = setInterval(()=> {
        hours = this.counterHours;
        minutes = this.counterMinutes;

        if(this.inProgress == true){ // Sigue corriendo el contador
          //console.log(`Guardar horas:${hours} - minutos:${minutes}`);
          // Guardar x las horas y minutos del curso ...
        }
        if(this.inProgress == false){ // Terminó el contador, detener el intervalo
          //console.error(`Finalizó horas:${hours} - minutos:${minutes}`);
          clearInterval(refreshInterval);
          // Guardar el curso como "finalizado" o algo asi...
        }
      }, 10000);
    },*/
    handleCountdownProgressMin(data){
      this.counterHoursMin = data.hours;
      this.counterMinutesMin = data.minutes;
      this.counterSecondsMin = data.seconds;
    },
    transformMin(props){
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`
      });
      return props;
    },
    saveProgressMin(){
      // Guardar el progreso (horas y minutos) cada 10 segundos
      let hours;
      let minutes;
      let seconds;
      this.refreshInterval = setInterval(()=> {
        hours = this.counterHoursMin;
        minutes = this.counterMinutesMin;
        seconds = this.counterSecondsMin;
        if(this.inProgressMin == true){ // Sigue corriendo el contador
          //console.log(`Guardar horas:${hours} - minutos:${minutes}- segundos:${seconds}`);
          this.saveProgressNow();
          // Guardar x las horas y minutos del curso ...
        }
        if(this.inProgressMin == false){ // Terminó el contador, detener el intervalo
          this.saveProgressNow();
          //console.error(`Finalizó horas:${hours} - minutos:${minutes}`);
          clearInterval(this.refreshInterval);
          // Guardar el curso como "finalizado" o algo asi...
        }
      }, 5000);
    },
  
    // == end counter ==
    openRefModal(urlFile){
      this.urlFile = urlFile;
      this.$refs['modal-reference'].show();
    },
    goBefore(){
      this.showExamBTN = false;
      window.scrollTo(0, 0); // Subir hasta arriba del scroll

      var beforeStep = this.step - 1;
      if((beforeStep - 1) <= this.module.subs.length){
        this.step = beforeStep;
      }


    },

    goNext(){
      
      window.scrollTo(0, 0); // Subir hasta arriba del scroll

      var nextStep = this.step + 1;
      if((nextStep + 1) <= this.module.subs.length){
        this.step = nextStep;
      }

      if((nextStep + 1) == this.module.subs.length){
        this.showExamBTN = true;
      }
  
      var data = {
          user_has_courses_id:this.$route.params.user_has_courses_id,
          topic_id:this.$route.params.module_id,
          slide_id:this.module.subs[this.step].id,
          hours:this.counterHoursMin,
          minutes:this.counterMinutesMin,
          seconds:this.counterSecondsMin
      };
      axios.post(tools.url('/api/saveTopic'),data).then((response)=>{

      }).catch((error)=>{
          console.log(error);
      });
    },

    getContent(){
      clearInterval(this.refreshInterval);
      
      this.modal.block = true;
      this.modal.icon = '';
      this.modal.msg = 'Loading, please wait...';
      this.$refs.modalloadinfg.open();

      window.scrollTo(0, 0); // Subir hasta arriba del scroll
      this.step = 0;
      this.showExamBTN = false;
      this.user_has_courses_id = this.$route.params.user_has_courses_id;
      this.module_id = this.$route.params.module_id;
       axios.get(tools.url('/api/user/module/'+ this.user_has_courses_id +'/' + this.module_id)).then((response)=>{
          this.module = response.data;
          
          
          if (this.module.current_topics_id != null) {
            this.step = this.module.current_topics_id;
          }

          if (this.module.subs.length > 0) {
            if((this.step + 1) == this.module.subs.length){
              this.showExamBTN = true;
            }
          }
          else{
            this.showExamBTN = true;
          }

          if(this.module.timeminms > 0){
              this.inProgressMin = true;
          }
          else{
              this.inProgressMin = false;
          }
          
          //this.saveProgress();
          this.saveProgressMin();
          this.modal.block = false;
          this.$refs.modalloadinfg.close();

       }).catch((error)=>{
         console.log(error);
         this.modal.block = false;
         this.$refs.modalloadinfg.close();
       });
    },
    formatLink(link){
      var basicLink = 'https://vimeo.com/';
      var embedLink = 'https://player.vimeo.com/video/';
      var videoID = null;
      var finalLink = '';

      if( link.toString().includes(basicLink) ){
        videoID = link.replace(basicLink, '');
        finalLink = embedLink + videoID;
      }else{
        var finalLink = embedLink + 'error';
      }

      return finalLink;
    },

    applyExam(){
   
      this.$router.push('/my-course/'+this.user_has_courses_id+'/exam/'+this.module_id);
    },
    nextModule(){   
      if(this.$parent.course.personal_questions == 1){
          if(this.step == 0 && this.module.position == 1){
              if(this.form_quiz.answer_1 == null){
                  this.modal.msg = 'Please complete all questions';
                  this.modal.icon = 'warning';
                  this.$refs.modalloadinfg.open();
                  return;
              }
              if(this.form_quiz.answer_2 == null){
                  this.modal.msg = 'Please complete all questions';
                  this.modal.icon = 'warning';
                  this.$refs.modalloadinfg.open();
                  return;
              }
              if(this.form_quiz.answer_3 == null){
                  this.modal.msg = 'Please complete all questions';
                  this.modal.icon = 'warning';
                  this.$refs.modalloadinfg.open();
                  return;
              }
              if(this.form_quiz.answer_4 == null){
                  this.modal.msg = 'Please complete all questions';
                  this.modal.icon = 'warning';
                  this.$refs.modalloadinfg.open();
                  return;
              }
              if(this.form_quiz.answer_5 == null){
                  this.modal.msg = 'Please complete all questions';
                  this.modal.icon = 'warning';
                  this.$refs.modalloadinfg.open();
                  return;
              }
              this.form_quiz.user_has_courses_id = this.$parent.user_has_courses_id;
              axios.post(tools.url('/api/saveQuiz'),this.form_quiz).then((response)=>{
                    this.$router.push('/my-course/'+this.user_has_courses_id+'/exam-finished/'+this.module_id);
              }).catch((error)=>{
                  console.log(error);
              });
          }
          else if(this.step + 1 == this.module.subs.length ){
              this.form_answer.user_has_courses_id = this.$parent.user_has_courses_id;
              this.form_answer.position = this.module.position;
              axios.post(tools.url('/api/validateQuiz'),this.form_answer).then((response)=>{
                  this.$router.push('/my-course/'+this.user_has_courses_id+'/exam-finished/'+this.module_id);
              }).catch((error)=>{
                  this.modal.msg = error.response.data.msg;
                  this.modal.icon = 'error';
                  this.modal.block = true;
                  this.$refs.modalloadinfg.open();

                  var self = this;
                  setTimeout(function(){
                    self.modal.block = false;
                    self.$refs.modalloadinfg.close();
                    this.$router.push('/account/my-courses');
                    
                  }, 2000);

                  return;
              });
          }
      }
      else{
          this.$router.push('/my-course/'+this.user_has_courses_id+'/exam-finished/'+this.module_id);
      }

      
    },
    playVoice(){
      if(this.ispaused == true){
          responsiveVoice.resume();
          this.isplaying = true;
      }
      else{
        var self = this;
        var res = $('.text-justify').text();
        responsiveVoice.speak(res, "US English Male",{onstart: self.StartCallback, onend: self.EndCallback});
        this.isplaying = true;
        this.ispaused = false;
      }
      
      console.log(res);
    },
    pauseVoice(){
        this.isplaying = false;
        this.ispaused = true;
        responsiveVoice.pause();
    },
    cancelVoice(){
      responsiveVoice.cancel();  
      this.isplaying = false;
        this.ispaused = false;
    },
    StartCallback(){

    },
    EndCallback(){
      responsiveVoice.cancel();  
      this.isplaying = false;
        this.ispaused = false;
    },  
    openModalWait(){
        this.modal.msg = 'Please wait until the timer ends to continue';
        this.modal.icon = 'warning';
 
        this.$refs.modalloadinfg.open();
    },
    saveProgressNow(){
      if(this.$route.meta.title == 'My course'){
        var data = {
            user_has_courses_id:this.$route.params.user_has_courses_id,
            topic_id:this.$route.params.module_id,
            slide_id:this.module.subs[this.step].id,
            hours:this.counterHoursMin,
            minutes:this.counterMinutesMin,
            seconds:this.counterSecondsMin
        };

        
        axios.post(tools.url('/api/saveTopic'),data).then((response)=>{

        }).catch((error)=>{
            console.log(error);
        });
      }
    }
  },

  watch: {
    "$route.query" : function(v){
      
      this.getContent();
    },
    'inProgress':function(){
      
        if (this.inProgress == false) {
          //this.$parent.$refs.modalalert.close();
            //this.$router.push('/account/my-courses');
        }
    }
  },

  beforeMount(){
  },

  mounted(){
    this.getContent();
    this.saveProgressNow();
  },
  destroyed() {
    clearInterval(this.refreshInterval);
  }
}
</script>
<style>
  .text-justify img{
        width: 60% !important;
        height: auto !important;
  }
  
</style>